
.operationPromotionStorePromotionAdd{
    .header{
        .headerTitle{
            color: #343434;
            font-size: 18px;
            padding-top: 16px;
            font-weight: bold;
        }
    }
    .banner{
        background: #ffffff;
        padding: 60px 20px;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        .bannerHeader{
            display: flex;
            img{
                margin-top: 20px;
            }
            .bannerHeaderOne{
                display: flex;
                flex-direction: column;
                align-items: center;
                span{
                    display: inline-block;
                    width: 48px;
                    height: 48px;
                    background: #DCDCDC;
                    color: #ffffff;
                    font-size: 24px;
                    text-align: center;
                    line-height: 48px;
                    border-radius: 50%;
                }
                .isActive{
                    background: #FD4446;
                }
                b{
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                    line-height: 1;
                    margin-top: 20px;
                }
            }
        }
    }
    .bannerFill{
        background: #ffffff;
    }
}
